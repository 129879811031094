import { _ } from "vue-underscore";
export default {
  name: "AssignUser",
  data() {
    return {
      usersList: [],
      selectedUser: null,
    };
  },
  props: {
    leadIds: [],
  },
  computed: {
    user() {
      return this.$store.state.userDetail;
    },
    // department() {
    //   return this.user.department;
    // },
  },
  methods: {
    assignAgent() {
      const _vm = this;
      _vm.axios
        .post("/assign-user", {
          ids: this.leadIds,
          user_id: this.selectedUser.id,
        })
        .then(function (response) {
          _vm.$emit("updateAssignment");
        })
        .catch(function () {});
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/department-wise-user/" + this.user.department_id)
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    this.getUserList();
  },
};
