import { _ } from "vue-underscore";

export default {
  name: "AddUser",

  data() {
    return {
      id: "",
      newComment: "",
      showModal: true,
      lead_feedback_comment: [],
    };
  },
  props: {
    selectedLead: {
      type: Array,
      required: true,
    },
  },

  computed: {},
  methods: {
    addFeedback() {
      let _vm = this;
      const leadIds = [];
      this.selectedLead.forEach((element) => {
        leadIds.push(element.id);
      });
      this.axios
        .post("/lead-feedback", {
          lead_id: leadIds,
          comment: this.newComment,
          is_feedback: 1,
        })
        .then(function (response) {
          _vm.showModal = false;
          _vm.newComment = "";
          _vm.$emit("success");

          _vm.lead_feedback_comment = response.data.data;
        })
        .catch(function (error) {});
    },
  },
  mounted() {},
};
